<template>
  <div class="record">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('RobotUserRecord.title')" label="Entrustment Records"></title-name>
    </div>

    <div class="list">
      <div class="list-name">
        <div>{{ $t('RobotUserRecord.date') }}</div>
        <div>{{ $t('RobotUserRecord.robot') }}</div>
        <div class="text-center">{{ $t('RobotUserRecord.jine') }}</div>
        <div class="text-right">{{ $t('RobotUserRecord.state') }}</div>
      </div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('RobotUserRecord.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
      <div  v-for="item in list" :key="item.id">
        <div class="list-val">
          <div>{{ item.cdate }}</div>
          <div>{{ item.name }}</div>
          <div class="text-center">
            {{ item.robotPrice }}
            <span v-if="item.cid == 2">USDT</span>
            <span v-if="item.cid == 1">CCAI</span>
            <span v-if="item.cid == 3">USDA</span>
          </div>
          <div class="text-right" v-if="item.state == 0 && item.cid == 1">{{$t('RobotUserRecord.shouyi')}}</div>
          <div class="text-right row justify-end" v-if="item.state == 0 && item.cid == 2">
            <div class="divestment-btn" @click="divestment(item.id)">{{$t('RobotUserRecord.shuhui')}}</div>
          </div>
          <div class="text-right" v-if="item.state == 1">{{$t('RobotUserRecord.yijieshu')}}</div>
          <div class="text-right" v-if="item.state == 2">{{$t('RobotUserRecord.yishuhui')}}</div>
        </div>
        <div class="total-row" v-if="item.cid == 2">
          <div class="total-item item-border" style="display: flex;">
            <div style="flex: 1;"></div>
            <div style="flex: 1;"></div>
            <div style="flex: 1;text-align: center;">
              <div style="color: gray;">{{$t('RobotUserRecord.ranshao')}}</div>
              {{parseInt(item.robotPrice / 0.9 * 0.1)}} 
              <span v-if="item.cid == 2">USDT</span>
              <span v-if="item.cid == 1">CCAI</span>
              <span v-if="item.cid == 1">USDA</span>
            </div>
            <div style="flex: 1;text-align: right;">
              <div style="color: gray;">{{$t('RobotUserRecord.suanli')}}</div>
              {{bili}}%
            </div>
          </div>
        </div>
        
      </div>
        
      </van-list>
      <empty :text="$t('RobotUserRecord.empty')" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List  } from "vant";

import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      zengzhi: ref(10),
      bili: ref(0),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getList()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getList()
    },
    setLanguageAfter() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getList()
    },
    getList() {
      let _this = this
      _this.$request.post(
        "api/RobotUser/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data.rtList
          let language = this.$utils.getloc("language")
          data.forEach(item => {
            if(language == 'zh') {
							item.name = item.robotName
						} else if(language == 'kor') {
							item.name = item.robotNameKor
						} else if(language == 'may') {
							item.name = item.robotNameMay
						} else if(language == 'vie') {
							item.name = item.robotNameVie
						} else if(language == 'th') {
							item.name = item.robotNameTh
						} else {
							item.name = item.robotNameEn
						}
          })
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          console.log(data)
          _this.list.push(...data)
          let bili = res.data.data.bili
          _this.bili = bili
          console.log(bili)
        }
      )
    },
    divestment(rid) {
      let _this = this
      this.$q.dialog({
        title: this.$t('Base.tishi'),
        message: this.$t('RobotUserRecord.message'),
        ok: {
          label: this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$t('RobotUserRecord.loading')
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/RobotUser/RtTk",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              signStr,
              rid
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                message: res.data.msg,
              });
              if(res.data.code == 100) {
                _this.pageIndex = 1
                _this.list = []
                _this.loading = false
                _this.finished = false
                _this.getList()
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.record {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.list {
  padding: 15px 15px 10px;
}

.list-name {
  display: flex;
  color: gray;
}

.list-name > div {
  flex: 1;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.list-val > div {
  flex: 1;
  font-size: 13px;
}

.divestment-btn {
  padding: 3px 12px;
  color: #fff;
  border-radius: 4px;
  background-color: #e02020;
}
.total-row {
  margin-top: 6px;
  display: flex;
}
.total-item {
  padding-bottom: 10px;
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(231, 228, 228, 0.7);
  border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
  border-right: 0;
}

.item-border {
  border-right: 1px solid rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
  font-weight: bold;
  font-size: 15px;
}
</style>